import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid #ccd2e3;
`;

export const AccordionItem = styled.div`
  padding: 16px 0;
  border-top: 1px solid #ccd2e3;
`;

export const AccordionHeader = styled.div`
  cursor: pointer;
  position: relative;

  ${({ tickStyle }) => {
    switch (tickStyle) {
      case 'chevron':
        return ``;
      default:
      case 'plus-minus-circle':
        return `
  & > .tick > div {
    content: '';
    display: block;
    position: absolute;
    border: 6px solid transparent;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-radius: 100%;
    margin-top: -6px;
    margin-left: -6px;
    transition: border 1s;
  }

  &.pressed > .tick > div {
    border: 6px solid #ccd2e3;
  }
  `;
    }
  }}
`;

export const AccordionBody = styled.div`
  overflow: hidden;
  position: relative;
  transition: height 500ms;
`;

export const AccordionTick = styled.div`
  ${({ tickStyle, active }) => {
    switch (tickStyle) {
      case 'chevron':
        return `
      box-sizing: border-box;
      position: absolute;
      right: 8px;
      top: 3px;
      display: block;
      width: 22px;
      height: 22px;
      border: 2px solid transparent;

      & > div {
        content: " ";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        left: 4px;
        top: 2px;
        color: #001c72;
        transition: all 500ms ease-in-out 200ms;
        ${active ? `transform: rotate(225deg);` : `transform: rotate(45deg);`}

      }

      `;
      default:
      case 'plus-minus-circle':
        return `
      background: #001c72;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  position: absolute;
  right: 8px;
  top: 3px;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    transition: transform 0.2s ease-in-out;
    opacity: 1;
    display: block;
    background: #fff;
  }

  &:before {
    width: 2px;
    height: 12px;
    top: 7px;
    left: 12px;
  }

  &:after {
    width: 12px;
    height: 2px;
    top: 12px;
    left: 7px;
  }
  ${({ active }) =>
    active
      ? `
  
  &:before {
    transform: rotate(90deg);
  }

  `
      : ``}

  &.enter-done {
    &:before,
    &:after {
      transform: rotate(90deg);
    }
    :after {
      transform: rotate(90deg);
      opacity: 0;
    }
  }
      `;
    }
  }}
`;
