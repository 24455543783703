export const sizeNames = ['xsmall', 'small', 'medium', 'large'];

export const sizes = {
  xsmall: {
    min: 0,
    default: 0,
    max: 0
  },
  small: {
    min: 320,
    default: 321,
    max: 640
  },
  medium: {
    min: 641,
    default: 642,
    max: 991
  },
  large: {
    min: 990,
    default: 991,
    max: 1200
  },
  getWidthForSize(size) {
    return this[size].default;
  },
  getSizeForWidth(width) {
    for (const size of sizeNames) {
      const value = this[size];
      if (width <= value.max) {
        return size;
      }
    }
    return 'large';
  }
};

export const responsiveStyles = responsiveStyles => {
  const reversedNames = sizeNames.slice().reverse();
  const styles = {};
  if (responsiveStyles) {
    for (const size of reversedNames) {
      if (size === 'large') {
        styles[`&`] = Object.assign({}, responsiveStyles[size]);
      } else {
        if (responsiveStyles[size]) {
          styles[`@media only screen and (max-width: ${sizes[size].max}px)`] = {
            '&': responsiveStyles[size]
          };
        }
      }
    }
  }
  return styles;
};
