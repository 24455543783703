import React, {
  useLayoutEffect,
  useState,
  useRef,
  createRef,
  useContext,
  useCallback
} from 'react';
import { BuilderBlockComponent } from '@builder.io/react';
import * as S from './AccordionV1.styles';
import { CSSTransition } from 'react-transition-group';
import { responsiveStyles } from '../../utils/builder';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';
import FunnelContext from '../../context/FunnelContext';
import useScreenResize from '../../hooks/useScreenResize';

const AccordionV1 = props => {
  const {
    items,
    children,
    openedIndex = -1,
    styles = 'default',
    tickStyle = 'plus-minus-circle'
  } = props;

  const [init, setInit] = useState(false);
  const refs = useRef(items.map(_ => createRef()));
  const [loading, setLoading] = useState(false);
  const { accordionActiveIndex, setAccordionActiveIndex } = useContext(
    FunnelContext
  );

  useLayoutEffect(() => {
    if (!init) {
      setAccordionActiveIndex(openedIndex);
      setInit(true);
    }
  }, [init, openedIndex, setInit, setAccordionActiveIndex]);

  const runItems = useCallback(
    (reset = false) => {
      refs.current.forEach((ref, index) => {
        if (reset) {
          ref.current.style.height = `unset`;
        }
        if (accordionActiveIndex === index) {
          setTimeout(() => {
            ref.current.style.height = `${ref.current.offsetHeight}px`;
          }, 1000);
        } else {
          ref.current.style.height = `0px`;
        }
      });
    },
    [accordionActiveIndex]
  );

  useLayoutEffect(() => {
    runItems();
  }, [runItems]);

  useButtonEventPressed('accordion-press-trigger');
  useScreenResize(() => runItems(true));

  const handleClick = index => {
    if (loading) return;
    setAccordionActiveIndex(prevState => {
      return index === prevState ? -1 : index;
    });
  };

  const duration = 250;
  const onEnter = node => {
    node.style.height = `${node?.childNodes[0].offsetHeight}px`;
    setLoading(true);
  };
  const onEntering = node => {
    node.style.height = `${node?.childNodes[0].offsetHeight}px`;
  };
  const onEntered = node => {
    node.style.height = `${node?.childNodes[0].offsetHeight}px`;
    if (loading) setLoading(false);
  };
  const onExit = node => {
    node.style.height = `0px`;
    setLoading(true);
  };
  const onExiting = node => {
    node.style.height = `0px`;
  };
  const onExited = node => {
    node.style.height = `0px`;
    if (loading) setLoading(false);
  };

  return (
    <S.Container>
      {items?.map((item, index) => {
        const { showTick = false, itemId } = item;
        const child = children[index];
        const isActive = accordionActiveIndex === index;
        const currentBlock = child.props.block;
        const itemStyles = responsiveStyles(currentBlock?.responsiveStyles);

        return (
          <S.AccordionItem
            key={child.key}
            className="accordion-item-v1"
            style={itemStyles}
            id={itemId ? itemId : `accordion-item-v1-${index}`}
            styles={styles}
          >
            <S.AccordionHeader
              className="accordion-header-v1 accordion-press-trigger"
              onClick={() => handleClick(index)}
              tickStyle={tickStyle}
            >
              {showTick ? (
                <CSSTransition in={isActive} timeout={0}>
                  <S.AccordionTick
                    active={isActive}
                    className="tick"
                    tickStyle={tickStyle}
                  >
                    <div></div>
                  </S.AccordionTick>
                </CSSTransition>
              ) : null}
              <BuilderBlockComponent block={currentBlock?.children[0]} />
            </S.AccordionHeader>
            <CSSTransition
              in={isActive}
              timeout={duration}
              onEnter={onEnter}
              onEntering={onEntering}
              onEntered={onEntered}
              onExit={onExit}
              onExiting={onExiting}
              onExited={onExited}
            >
              <S.AccordionBody
                className="accordion-body-v1"
                ref={refs.current[index]}
              >
                <BuilderBlockComponent block={currentBlock?.children[1]} />
              </S.AccordionBody>
            </CSSTransition>
          </S.AccordionItem>
        );
      })}
    </S.Container>
  );
};

export default AccordionV1;
